<template>
  <v-app-bar
    class="elevation-0 flat px-md-16 px-lg-16"
    :height="xs ? 220 : 120"
    style="display: flex"
  >
    <v-row v-if="!xs" style="display: flex; align-items: center">
      <v-app-bar-title class="mt-4">
        <v-card @click="showWelcome">
          <v-img
            style="height: 80%; width: 250px"
            src="@/assets/logo_with_stars.svg"
          />
        </v-card>
      </v-app-bar-title>
      <v-spacer />
      <!--      <v-btn-->
      <!--        class="mr-2"-->
      <!--        width="120"-->
      <!--        variant="outlined"-->
      <!--      >-->
      <!--        Portfolio-->
      <!--      </v-btn>-->
      <v-btn class="mr-8" width="120" @click="showAbout()"> ABOUT </v-btn>
    </v-row>
    <v-container v-else fluid class="pa-0">
      <v-col class="pt-0" style="text-align: -webkit-center">
        <v-img style="width: 30vmin" src="@/assets/logo_with_stars.svg" />
      </v-col>

      <v-row style="align-items: center" class="pl-6">
        <!--        <v-btn-->
        <!--          class="ml-4"-->
        <!--          width="120"-->
        <!--          variant="outlined"-->
        <!--        >-->
        <!--          Portfolio-->
        <!--        </v-btn>-->
        <v-spacer />
        <v-btn class="mr-8" width="120" @click="showAbout()"> ABOUT </v-btn>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import router from "@/router";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "NavBar",
  setup() {
    const { name, xs } = useDisplay();

    // eslint-disable-next-line vue/return-in-computed-property
    const height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return 240;
        case "sm":
          return 240;
        case "md":
          return 120;
        case "lg":
          return 120;
        case "xl":
          return 120;
        case "xxl":
          return 120;
      }
    });

    return { xs, height };
  },
  methods: {
    showWelcome() {
      console.log("Click click boom");
      router.push("/");
    },
    showAbout() {
      console.log("showAbout click boom");
      router.push("/about");
    },
  },
};
</script>

<style scoped></style>

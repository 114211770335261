
import { defineComponent } from "vue";
// import HelloWorld from "@/components/HelloWorld.vue";
// import NavBar from "@/components/NavBar.vue"; // @ is an alias to /src
import Test from "@/components/Test.vue";
// import AboutView from "@/views/AboutView.vue";
import WelcomeContainer from "@/components/WelcomeContainer.vue";
// import { tr } from "vuetify/lib/locale";

export default defineComponent({
  name: "HomeView",
  components: {
    WelcomeContainer,
    // AboutView,
    // NavBar,
    // HelloWorld,
    Test,
  },
  data() {
    return{
      showMainContent: false
    };
  },
  methods: {
    changeComponent()  {
      this.showMainContent = true
    }
  }
});

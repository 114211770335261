<template>
  <!--  <v-card width="300" height="300" elevation="3">-->
  <v-container
    fluid
    class="mt-3 ml-1"
    style="height: 75vh;"
  >
    <v-row class="pa-0">
      <v-spacer />
      <v-col class=" mt-md-16">
        <welcome-animated-text />
      </v-col>
      <v-col>
        <v-img
          style="height: 90vmin; width: 90vmin; background-color: white "
          src="@/assets/macbook_memoji2.svg"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WelcomeAnimatedText from "@/components/WelcomeAnimatedText";
export default {
  name: "WelcomeContainer",
  components: { WelcomeAnimatedText },
};
</script>

<style scoped>
.typewriter {
  border-right: solid 3px rgba(0, 0, 0, 0.75);
  white-space: nowrap;
  overflow: hidden;
  font-family: "Source Code Pro", monospace;
  font-size: 28px;
  color: rgba(0, 0, 0, 0.7);
}

/* Animation */
.typewriter {
  animation: animated-text 4s steps(25, end) 1s 1 normal both,
    animated-cursor 600ms steps(25, end) infinite;
}

/* text animation */

@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* cursor animations */

@keyframes animated-cursor {
  from {
    border-right-color: rgba(0, 0, 0, 1);
  }
  to {
    border-right-color: transparent;
  }
}
</style>

<template>
  <v-container
    id="typed"
    class="container elevation-0 typed"
  />
</template>

<script>
export default {
  name: "WelcomeAnimatedText",
  data() {
    return{
      welcomeText: "Hi, My name is Misha. I make mobile apps. Also, I ride BMX and shoot 35mm film.",
      textLength: 0
    };
  },
  mounted() {
    this.animateWelcomeText();
  },
  methods: {
    animateWelcomeText()  {
      console.log("animateWelcomeText fired")
      let textChar = this.welcomeText.charAt(this.textLength++);
      let paragraph = document.getElementById("typed");
      let charElement = document.createTextNode(textChar);
      paragraph.appendChild(charElement);

      if (this.textLength < this.welcomeText.length + 1) {
        if (this.welcomeText.charAt(this.textLength) === ".") {
          console.log("GOT THE PERIOD!!");
        }
        setTimeout(this.animateWelcomeText, 50);
      } else {
        this.welcomeText = '';
      }
    }
    }
};
</script>

<style scoped>
.container {
  min-width: 430px;
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*color: rgb(245, 245, 245);*/
  /*background-color: rgb(15, 15, 15);*/
}

.typed {
  line-height: 50px;
  width: 80%;
  font-size: 2em;
  text-align: left;
  font-family: "Courier New", "Lucida Console", monospace;
  vertical-align: text-bottom;
}

.typed::after {
  content: "|";
  font-weight: bold;
  font-size: 1em;
  line-height: 40px;
  animation: blink 1.0s step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    /*color: yellow;*/
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>

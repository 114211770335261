import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Test = _resolveComponent("Test")!
  const _component_welcome_container = _resolveComponent("welcome-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.showMainContent)
      ? (_openBlock(), _createBlock(_component_Test, {
          key: 0,
          onCloseAnimation: _ctx.changeComponent
        }, null, 8, ["onCloseAnimation"]))
      : _createCommentVNode("", true),
    (_ctx.showMainContent)
      ? (_openBlock(), _createBlock(_component_welcome_container, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}
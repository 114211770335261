
import { defineComponent } from "vue";
import NavBar from "@/components/NavBar.vue";

export default defineComponent({
  name: "App",
  components: { NavBar },
  data() {
    return {
      animatingLogo: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.animatingLogo = false;
    }, 5000);
  }
});
